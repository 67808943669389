import {
  GET_FOURNITURE_FAILD,
  GET_FOURNITURE_LOAD,
  GET_FOURNITURE_SUCCESS,
  GET_ONE_FOURNITURE,
} from "../constants/fournitures";

const initialState = {
  fournitures: [],
  fourniture: {},
  isLoad: false,
  isError: false,
};

const fournitureReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_FOURNITURE_SUCCESS:
      return {
        ...state,
        fournitures: payload.fournitures,
        isLoad: false,
      };
    case GET_ONE_FOURNITURE:
      return { ...state, fourniture: payload.result, isLoad: false };
    case GET_FOURNITURE_LOAD:
      return { ...state, isLoad: true };
    case GET_FOURNITURE_FAILD:
      return {
        ...state,
        isLoad: false,
        isError: true,
      };

    default:
      return state;
  }
};

export default fournitureReducers;
