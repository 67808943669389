import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router";
import "./App.css";
import MainDash from "./components/MainDash";
import Naav from "./components/Naav";
import Navbar from "./components/Navbar";
import SidebarDash from "./components/SidebarDash";
import DashboardMain from "./Dashboard/DashboardMain";
import { current } from "./JS/actions/member";
import { CURRENT_USER } from "./JS/constants/member";
import AddEvent from "./pages/AddEvent";
import AddFamily from "./pages/AddFamily";
import AddUser from "./pages/AddUser";
import Contact from "./pages/Contact";
import error from "./pages/error";
import events from "./pages/Events";
import home from "./pages/home";
import ListEvents from "./pages/ListEvents";
import ListFamily from "./pages/ListFamily";
import ListSecourisme from "./pages/ListSecourisme"
import ListOthers from "./pages/ListOthers"

import ListUsers from "./pages/ListUsers";
import AddFacture from "./pages/AddFacture";
import addOther from "./pages/addOther";


import Login from "./pages/Login";
import Profile from "./pages/Profile";
import Register from "./pages/Register";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import ListFactures from "./pages/ListFactures";
import Logistique from "./components/Logistique";

import AddNourr from "./pages/AddNourr";
import AddFourr from "./pages/AddFourr";
import AddSecourr from "./pages/AddSecourr";
import ListNourritures from "./pages/ListNourritures";
import ListFourniture from "./pages/ListFourniture";
import Vibes from "./components/Vibes";
import AddSolde from "./pages/AddSolde";
import AddOther from "./pages/addOther";

function App() {

  const user = useSelector((state) => state.memberReducers.member);
  const auth = useSelector((state) => state.memberReducers.isAuth);
  const load = useSelector((state) => state.memberReducers.isLoad);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const openSidebar = () => {
    setSidebarOpen(true);
  };
  const closeSidebar = () => {
    setSidebarOpen(false);
  };
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  useEffect(() => {
    if (token) dispatch(current());
  }, [dispatch, token]);
  return (
    <div>


      <Switch>
        <Route exact path="/" component={home} />
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/contact" component={Contact} />


        <div className="MainSec">
          {token ? (
            <div className="containerDashMain">
            <div className="containerDash">
              <SidebarDash
                sidebarOpen={sidebarOpen}
                closeSidebar={closeSidebar}
              /> </div>
              <div className="secMainConainer">
              <Navbar  sidebarOpen={sidebarOpen} openSidebar={openSidebar} />{" "}
              <PrivateRoute path="/dashboard" component={MainDash} />

              <div className="secMainConainer-cont">
              <Route path="/event" component={events} />
              <Route path="/profile" component={Profile} />
              <Route path="/Logistique" component={Logistique} />
            {/* All routes adding................ */}
               <Route key="/addFamily"  exact path="/addFamily" component={AddFamily} />
               <Route key="/editFamily" exact path="/editFamily/:id" component={AddFamily} />
               <Route path="/AddFacture" component={AddFacture} />
               <Route key="/addNourritures"   exact path="/addNourritures" component={AddNourr} />
               
               <Route key="/editNourritures"  exact path="/editNourritures/:id" component={AddNourr} />

               <Route key="/addFournitures" exact path="/addFournitures" component={AddFourr} />
               <Route key="/editFounitures"   exact path="/editFounitures/:id" component={AddFourr} />

               <Route  key="/AddSecourr" exact path="/AddSecourisme" component={AddSecourr} />
                <Route key ="editSecourisme"    exact path="/editSecourisme/:id" component={AddSecourr} />

               <Route key="/AddOther" exact path="/AddOther" component={AddOther} />
               <Route key="/editOther" exact path="/editOther/:id"   component={AddOther} />


               <Route key="/AddUser" exact path="/AddUser" component={AddUser} />
               <Route key="/editUser" exact path="/editUser/:id" component={AddUser} />
               <Route key="/Addevent" exact path="/Addevent" component={AddEvent} />
               <Route key="/edit" exact path="/edit/:id" component={AddEvent} />
               <Route path="/addSolde" component={AddSolde} />

             {/* All routes of listing............ */}
                <Route path="/Nourritures" component={ListNourritures} />
                <Route path="/Fournituress" component={ListFourniture} />
                <Route path="/Secourismes" component={ListSecourisme} />
                <Route path="/users" component={ListUsers} />
                <Route path="/family" component={ListFamily} />
                <Route path="/others" component={ListOthers} />
                <Route path="/vibes" component={Vibes} />
                <Route path="/eventss" component={ListEvents} />
                <Route path="/Factures" component={ListFactures} />

              </div>
            </div></div>
          ) : null}
        </div>
        <Route path="/*" component={error} />
      </Switch>
    </div>
  );
}

export default App;
