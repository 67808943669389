import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getallEvent } from "../JS/actions/Event";
import Events from "./Events";
import "./ListEvents.css";

const ListEvents = () => {
  const events = useSelector((state) => state.eventReducers.events);
  console.log(events);
  const isLoad = useSelector((state) => state.eventReducers.isLoad);
  const isError = useSelector((state) => state.eventReducers.isError);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getallEvent());
  }, [dispatch]);
  return (
    <div>
      <h1
        style={{
          textAlign: "center",
          position: "sticky",
          top: "0px",
          zIndex: "99",
          background: "white",
          padding: "10px 0",
        }}
      >
        List events
      </h1>

      {isLoad ? (
        <div className="loading">Loading&#8230</div>
      ) : isError ? (
        <h1> Error to get the data </h1>
      ) : (
        <div className="main">
          {events && events.map((el) => <Events event={el} key={el._id} />)}
        </div>
      )}
    </div>
  );
};

export default ListEvents;
