import React from "react";
import { Carousel } from "react-bootstrap";
import crt4 from "../assets/img/crt4.jpg";
import crt3 from "../assets/img/crt3.jpg";
import crt1 from "../assets/img/crt1.jpg";
// import grp2 from "../assets/img/grp2.jpg";
import NV1 from "../assets/img/NV1.jpg";

const Sliide = () => {
  return (
    <div className="main">
      <Carousel>
        <Carousel.Item>
          <img className="d-block w-100" src={crt3} alt="First slide" />
          <Carousel.Caption>
            <h3>Humanity First</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={NV1} alt="First slide" />
          <Carousel.Caption>
            <h3>Humanity First</h3>
          </Carousel.Caption>
        </Carousel.Item>
        {/* <Carousel.Item>
          <img className="d-block w-100" src={grp2} alt="First slide" />
          <Carousel.Caption>
            <h3>Humanity First</h3>
          </Carousel.Caption>
        </Carousel.Item> */}
        <Carousel.Item>
          <img className="d-block w-100" src={crt1} alt="Second slide" />

          <Carousel.Caption>
            <h3>Humanity first</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={crt4} alt="Third slide" />

          <Carousel.Caption>
            <h3>Humanity first</h3>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default Sliide;
