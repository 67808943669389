import React from "react";
import { FaPen } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteOther, getOneOther } from "../JS/actions/other";
import "./Other.css";
const Other = ({ oth }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.memberReducers.member);

  const handleDelete = () => {
    const result = window.confirm("are you sure");
    if (result) {
      dispatch(deleteOther(oth._id));
    }
  };
  const handleOth = () => {
    dispatch(getOneOther(oth._id));
  };
  return (
    <div class="main-container">
      <div class="heading"></div>
      <div class="cardss">
        <div class="cardd card-7">
          <div class="card__icon">
            <b> {oth.title} </b>
            {/* <FaCalendarPlus style={{ fontSize: "29px", color: "white" }} />{" "} */}
          </div>
          <p class="card__exit">
            {/* <i class="fas fa-times"></i> */} Quantity : {oth.qte}{" "}
            {/* {secourism.description} */}
          </p>
          <p class="card__title">{oth.description}</p>
          <p class="card__apply">
            {user.role == "admin" ? (
              <a onClick={handleDelete} class="card__link">
                Delete Now{" "}
                <MdDelete style={{ color: "white", fontSize: "22px" }} />{" "}
              </a>
            ) : user.role == "logistique" ? (
              <a onClick={handleDelete} class="card__link">
                Delete Now{" "}
                <MdDelete style={{ color: "white", fontSize: "22px" }} />{" "}
              </a>
            ) : null}
            {user.role == "admin" ? (
              <Link to={`/editOther/${oth._id}`}>
                <a onClick={handleOth} class="card__link">
                  Update{" "}
                  <FaPen
                    // onClick={handleEvent}
                    style={{ color: "green", fontSize: "18px" }}
                  />
                </a>
              </Link>
            ) : user.role == "logistique" ? (
              <Link to={`/editOther/${oth._id}`}>
                <a onClick={handleOth} class="card__link">
                  Update{" "}
                  <FaPen
                    // onClick={handleEvent}
                    style={{ color: "green", fontSize: "18px" }}
                  />
                </a>
              </Link>
            ) : null}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Other;
