import React, { useEffect, useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { addSecourisme, editSecourisme } from "../JS/actions/secourisme";

const AddSecourr = () => {
  const [secourisme, setSecourisme] = useState({});
  const [edit, setEdit] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const secToEdit = useSelector((state) => state.secourismeReducers.secourisme);
  useEffect(() => {
    if (params.id) {
      setEdit(true);
    } else {
      setEdit(false);
    }
    edit
      ? setSecourisme(secToEdit)
      : setSecourisme({
          title: "",
          description: "",
          qte: "",
        });
  }, [params, edit, secToEdit]);
  const handleChange = (e) => {
    setSecourisme({ ...secourisme, [e.target.name]: e.target.value });
  };
  const handleSave = (e) => {
    e.preventDefault();
    if (edit) {
      dispatch(editSecourisme(params.id, secourisme, history));
      alert("secourisme updated succesfully :)");
    } else {
      dispatch(addSecourisme(secourisme, history));
      alert("sec added succesfully :)");
    }
  };
  return (
    <div>
      <Form className="addFamily">
        <h1 style={{ textAlign: "center", color: "black" }}>
          {" "}
          Ajouter un element secourisme{" "}
        </h1>

        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Titre</Form.Label>
            <Form.Control
              onChange={handleChange}
              type="text"
              placeholder="Entrez le titre"
              name="title"
              required
              value={secourisme.title}
            />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label> Desription</Form.Label>
            <Form.Control
              onChange={handleChange}
              type="text"
              placeholder="Description"
              name="description"
              required
              value={secourisme.description}
            />
          </Form.Group>
        </Row>
        <Form.Group as={Col}>
          <Form.Label>Quantité</Form.Label>
          <Form.Control
            onChange={handleChange}
            type="number"
            placeholder="qte"
            name="qte"
            required
            value={secourisme.qte}
          />
        </Form.Group>
        {/* <Form.Group as={Col} controlId="formGridState">
          <Form.Label>Type event</Form.Label>
          <Form.Select
            onChange={handleChange}
            placeholder="Type event"
            name="eventType"
            type="text"
            defaultValue="Choose..."
            required
          >
            <option>Choisir...</option>
            <option>evennement</option>
            <option>action</option>
          </Form.Select>
        </Form.Group> */}

        <Row className="mb-3"></Row>

        <Button
          onClick={handleSave}
          style={{ marginBottom: "15px" }}
          variant="primary"
          type="submit"
        >
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default AddSecourr;
