import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { clearErrors } from "../JS/actions/member";
export const Notifcation = ({ error }) => {
  const [show, setshow] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      setshow(false);
      dispatch(clearErrors());
    }, 4000);
  }, [show, dispatch]);
  return (
    <div>
      {show && (
        <div style={{ background: "black" }}>
          {" "}
          {toast(error.msg)}
          <ToastContainer
            position="top-right"
            style={{
              position: "absolute",
              zIndex: "999",
              top: "15px",
              right: "15px",
            }}
          />{" "}
        </div>
      )}
    </div>
  );
};
