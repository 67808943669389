import React from "react";
import Contact from "./Contact";
import Sliide from "./Sliide";

const home = () => {
  return (
    <div>
      <Sliide />
      <Contact />
    </div>
  );
};

export default home;
