import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./register.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCocktail } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../JS/actions/member";
import { Notifcation } from "../components/Notifcation";

const Login = () => {
  const errors = useSelector((state) => state.memberReducers.errors);

  const [member, setmember] = useState({
    email: "",
    password: "",
  });
  const dispatch = useDispatch();

  const history = useHistory();
  const handleMember = (e) => {
    setmember({ ...member, [e.target.name]: e.target.value });
  };
  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(login(member, history));
    setmember({
      email: "",
      password: "",
    });
  };
  return (
    <div>
      {errors && errors.map((el) => <Notifcation error={el} />)}

      <div className="container" id="container">
        <div className="form-container log-in-container">
          <form action="#">
            <h1>Login</h1>
            <div className="social-container">
              <a href="#" className="social">
                <FontAwesomeIcon
                  style={{ color: "#4267B2", fontSize: "30px" }}
                  icon={faFacebook}
                ></FontAwesomeIcon>
              </a>
              <a href="#" className="social">
                <FontAwesomeIcon
                  style={{ color: "#E1306C", fontSize: "30px" }}
                  icon={faInstagram}
                ></FontAwesomeIcon>
              </a>
            </div>
            <span>Hello friend</span>
            <input
              onChange={handleMember}
              required
              name="email"
              type="email"
              placeholder="Email"
              value={member.email}
            />
            <input
              onChange={handleMember}
              required
              name="password"
              type="password"
              placeholder="Password"
              value={member.password}
            />
            <span style={{ color: "red" }}> {member.msg} </span>
            <button onClick={handleLogin}>Log In</button>
            <a href="#">Or create your account</a>
          </form>
        </div>
        <div className="overlay-container">
          <div className="overlay">
            <div className="overlay-panel overlay-right">
              <h1>Humanity</h1>
              <p>WE ARE ALWAYS TOGHETER ... HUMANITY FIRST</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
