import React, { useEffect } from "react";
import "./SideBar.css";
import white_logo from "../assets/img/white_logo.png";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  FaFeatherAlt,
  FaFileInvoiceDollar,
  FaHouseUser,
  FaKey,
  FaRocket,
  FaSmileBeam,
  FaUserAlt,
} from "react-icons/fa";
import { logout } from "../JS/actions/member";
import {
  MdFollowTheSigns,
  MdOutlineBuildCircle,
  MdSnowshoeing,
} from "react-icons/md";
import { useHistory } from "react-router";
const SidebarDash = ({ sidebarOpen, closeSidebar }) => {
  const user = useSelector((state) => state.memberReducers.member);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = () => {
    dispatch(logout());
    history.push("/login");
  };

  return (
    <div className={sidebarOpen ? "sidebar-responsive" : ""} id="sidebar">
      <div className="sidebar__title">
        <div className="sidebar__img">
          <img src={white_logo} alt="logo" />
          <h1>Croissant Rouge Tunisien Comité local de Radès </h1>
        </div>
        <i
          onClick={() => closeSidebar()}
          className="fa fa-times"
          id="sidebarIcon"
          aria-hidden="true"
        ></i>
      </div>

      <div className="sidebar__menu">
        <div className="sidebar__link active_menu_link">
          <FaFeatherAlt
            style={{ fontSize: "15px", marginRight: "6px", color: "blue" }}
          />
          <a>
            {" "}
            <Link className="link" to="/dashboard">
              {" "}
              Dashboard{" "}
            </Link>
          </a>
        </div>
        <h2>MNG</h2>

        <div className="sidebar__link">
          {/* <FaKey
              style={{ fontSize: "15px", marginRight: "6px", color: "red" }}
            /> */}
          <FaSmileBeam
            style={{ fontSize: "20px", color: "red", marginRight: "6px" }}
          />
          <a>
            {" "}
            <Link className="link" to="/vibes">
              {" "}
              Gallery / Positive Vibes{" "}
            </Link>{" "}
          </a>
        </div>

        <div className="sidebar__link">
          <FaUserAlt
            style={{ fontSize: "15px", marginRight: "6px", color: "#35a4ba" }}
          />

          <a>
            {" "}
            <Link className="link" to="/users">
              {" "}
              Membres{" "}
            </Link>{" "}
          </a>
        </div>
        <div className="sidebar__link">
          <FaHouseUser
            style={{ fontSize: "15px", marginRight: "6px", color: "#38e1b0" }}
          />
          <a>
            {" "}
            <Link className="link" to="/family">
              {" "}
              Familles{" "}
            </Link>{" "}
          </a>
        </div>

        <div className="sidebar__link">
          <FaRocket
            style={{ fontSize: "15px", marginRight: "6px", color: "#f65a6f" }}
          />
          <a>
            {" "}
            <Link className="link" to="/eventss">
              {" "}
              Events{" "}
            </Link>{" "}
          </a>
        </div>

        {user && user.role == "admin" && (
          <div className="sidebar__link">
            <FaFileInvoiceDollar
              style={{
                fontSize: "15px",
                marginRight: "6px",
                color: "chartreuse",
              }}
            />
            <a>
              {" "}
              <Link className="link" to="/Factures">
                {" "}
                Factures{" "}
              </Link>
            </a>
          </div>
        )}
        <div className="sidebar__link">
          <MdOutlineBuildCircle
            style={{
              fontSize: "23px",
              marginRight: "6px",
              color: "orange",
            }}
          />

          <a>
            {" "}
            <Link className="link" to="/Logistique">
              {" "}
              Logistique{" "}
            </Link>{" "}
          </a>
        </div>
        <h2>News </h2>

        <div className="sidebar__link">
          <i className="fa fa-question"></i>
          <a href="#">news (Soon ... ) </a>
        </div>
        <div className="sidebar__link">
          <i className="fa fa-question"></i>
          <a href="#">news (Soon ... ) </a>
        </div>

        {/* <h2>PAYROLL</h2>
        <div className="sidebar__link">
          <i className="fa fa-money"></i>
          <a href="#">Payroll</a>
        </div> */}
        {/* <div className="sidebar__link">
          <i className="fa fa-briefcase"></i>
          <a href="#">Paygrade</a>
        </div> */}
        <div className="sidebar__logout">
          <MdSnowshoeing
            style={{ fontSize: "20px", marginRight: "6px", color: "blue" }}
          />
          <a onClick={handleLogout}>Log out {user && user.firstName} </a>
        </div>
      </div>
    </div>
  );
};

export default SidebarDash;
