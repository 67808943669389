import React from "react";
import { FaCalendarPlus, FaPen } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteSecourisme, getOneSecourisme } from "../JS/actions/secourisme";
import "./Secourisme.css";
const Secourisme = ({ secourism }) => {
  const user = useSelector((state) => state.memberReducers.member);

  const dispatch = useDispatch();
  const handleSecourisme = () => {
    dispatch(getOneSecourisme(secourism._id));
  };
  const handleDelete = () => {
    const result = window.confirm("are you sure");
    if (result) {
      dispatch(deleteSecourisme(secourism._id));
    }
  };
  return (
    <div class="main-container">
      <div class="heading"></div>
      <div class="cardss">
        <div class="cardd card-6">
          <div class="card__icon">
            <b> {secourism.title} </b>
            <FaCalendarPlus style={{ fontSize: "29px", color: "white" }} />{" "}
          </div>
          <p class="card__exit">
            {/* <i class="fas fa-times"></i> */} Quantity : {secourism.qte}{" "}
            {/* {secourism.description} */}
          </p>
          <p class="card__title">{secourism.description}</p>
          <p class="card__apply">
            {user.role == "admin" ? (
              <a onClick={handleDelete} class="card__link">
                Delete Now{" "}
                <MdDelete style={{ color: "white", fontSize: "22px" }} />{" "}
              </a>
            ) : user.role == "logistique" ? (
              <a onClick={handleDelete} class="card__link">
                Delete Now{" "}
                <MdDelete style={{ color: "white", fontSize: "22px" }} />{" "}
              </a>
            ) : null}

            {user.role == "admin" ? (
              <Link to={`/editSecourisme/${secourism._id}`}>
                <a onClick={handleSecourisme} class="card__link">
                  Update{" "}
                  <FaPen
                    // onClick={handleEvent}
                    style={{ color: "green", fontSize: "18px" }}
                  />
                </a>
              </Link>
            ) : user.role == "logistique" ? (
              <Link to={`/editSecourisme/${secourism._id}`}>
                <a onClick={handleSecourisme} class="card__link">
                  Update{" "}
                  <FaPen
                    // onClick={handleEvent}
                    style={{ color: "green", fontSize: "18px" }}
                  />
                </a>
              </Link>
            ) : null}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Secourisme;
