import {
  BYE_BYE,
  CLEAR_ERRORS,
  CURRENT_USER,
  FAILD,
  GET_ONE_USER,
  LOAD,
  LOGIN_MEMBER,
  LOGOUT_MEMBER,
  REGISTER_MEMBER,
  SUCCESS,
} from "../constants/member";
import axios from "axios";
export const register = (newUser, history) => async (dispatch) => {
  dispatch({ type: LOAD });
  try {
    let { data } = await axios.post("/api/member/register", newUser);
    await dispatch({ type: REGISTER_MEMBER, payload: data });
    history.push("/login");
  } catch (error) {
    // console.log(error);
    dispatch({ type: FAILD, payload: error.response.data });
  }
};

export const logout = () => {
  return { type: LOGOUT_MEMBER };
};

export const login = (member, history) => async (dispatch) => {
  dispatch({ type: LOAD });
  try {
    let { data } = await axios.post("/api/member/login", member);
    dispatch({ type: LOGIN_MEMBER, payload: data });
    history.push("/dashboard");
  } catch (error) {
    dispatch({ type: FAILD, payload: error.response.data });
  }
};

export const deleteUser = (id) => async (dispatch) => {
  const config = {
    headers: {
      authorization: localStorage.getItem("token"),
    },
  };
  dispatch({ type: LOAD });
  try {
    await axios.delete(`/api/member/${id}`, config);
    dispatch(getallUser());
  } catch (error) {
    dispatch({ type: FAILD });
    console.log(error);
  }
};

export const getallUser = () => async (dispatch) => {
  dispatch({ type: LOAD });

  try {
    let results = await axios.get("/api/member/members");
    // if i send an http request result bech tekho objet { status , headers ,data li jet mel api }
    dispatch({
      type: SUCCESS,
      payload: results.data, // (hedhy tji feha un objet fih msg w FAMILY)
    });
  } catch (error) {
    dispatch({ type: FAILD });
    console.log(error);
  }
};

export const addUser = (user, history) => async (dispatch) => {
  dispatch({ type: LOAD });
  try {
    await axios.post("/api/member", user);

    history.push("/users");
  } catch (error) {
    dispatch({ type: FAILD });
    console.log(error);
  }
};

export const editUser = (id, userr, history) => async (dispatch) => {
  const config = {
    headers: {
      authorization: localStorage.getItem("token"),
    },
  };
  try {
    await axios.put(`/api/member/${id}`, userr, config);
    dispatch(getallUser());
    alert("update successfully");
    history.push("/users");
  } catch (error) {
    dispatch({ type: FAILD });
    console.log(error);
  }
};

export const getoneUser = (id) => async (dispatch) => {
  dispatch({ type: LOAD });
  try {
    let result = await axios.get(`/api/member/oneMember/${id}`);
    dispatch({ type: GET_ONE_USER, payload: result.data });
  } catch (error) {
    dispatch({ type: FAILD });
  }
};

export const current = () => async (dispatch) => {
  const config = {
    headers: {
      authorization: localStorage.getItem("token"),
    },
  };
  dispatch({ type: LOAD });
  try {
    let { data } = await axios.get("/api/member/current", config);
    dispatch({ type: CURRENT_USER, payload: data });
  } catch (error) {
    dispatch({ type: FAILD, payload: error.response.data });
  }
};

export const memberBanned = (statut, id) => async (dispatch) => {
  const config = {
    headers: {
      authorization: localStorage.getItem("token"),
    },
  };

  try {
    await axios.put(`api/member/banned/${id}`, statut, config);
    dispatch(getallUser());
  } catch (error) {
    dispatch({ type: FAILD, payload: error.response.data });
    console.log(error);
  }
};

export const memberVerified = (verified, id) => async (dispatch) => {
  const config = {
    headers: {
      authorization: localStorage.getItem("token"),
    },
  };

  try {
    await axios.put(`api/member/verified/${id}`, verified, config);
    dispatch(getallUser());
  } catch (error) {
    dispatch({ type: FAILD, payload: error.response.data });
    console.log(error);
  }
};

export const clearErrors = () => {
  return { type: CLEAR_ERRORS };
};
