import React from "react";
import { FaBookOpen, FaPen } from "react-icons/fa";
import { MdDelete, MdFastfood } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteFourniture, getOneFourr } from "../JS/actions/fournitures";

import "./Fournitures.css";

const Fournitures = ({ fourniture }) => {
  const user = useSelector((state) => state.memberReducers.member);

  const dispatch = useDispatch();
  const handleDelete = () => {
    const result = window.confirm("are you sure");
    if (result) {
      dispatch(deleteFourniture(fourniture._id));
    }
  };
  const handleFourr = () => {
    dispatch(getOneFourr(fourniture._id));
  };
  return (
    <div class="main-container">
      <div class="heading">
        {/* <h1 class="heading__title">Stock Nourritures</h1> */}
      </div>
      <div class="cardss">
        <div class="cardd card-5">
          <div class="card__icon">
            <b> {fourniture.title} </b>
            <FaBookOpen style={{ fontSize: "30px", color: "#38e1b0" }} />{" "}
          </div>
          <p class="card__exit">
            {/* <i class="fas fa-times"></i> */} Quantity : {fourniture.qte}
          </p>
          <p class="card__title">{fourniture.description}</p>
          <p class="card__apply">
            {user.role == "admin" ? (
              <a onClick={handleDelete} class="card__link" href="#">
                Delete <MdDelete style={{ color: "red", fontSize: "22px" }} />{" "}
              </a>
            ) : user.role == "logistique" ? (
              <a onClick={handleDelete} class="card__link" href="#">
                Delete <MdDelete style={{ color: "red", fontSize: "22px" }} />{" "}
              </a>
            ) : null}{" "}
            {user.role == "admin" ? (
              <Link to={`/editFounitures/${fourniture._id}`}>
                <a onClick={handleFourr} class="card__link" href="#">
                  Update <FaPen style={{ color: "green", fontSize: "18px" }} />
                </a>
              </Link>
            ) : user.role == "logistique" ? (
              <Link to={`/editFounitures/${fourniture._id}`}>
                <a onClick={handleFourr} class="card__link" href="#">
                  Update <FaPen style={{ color: "green", fontSize: "18px" }} />
                </a>
              </Link>
            ) : null}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Fournitures;
